import { createSelector, createSlice } from "@reduxjs/toolkit";
import { CACHE_KEY } from "../../utils/constants";
import { getEnvChain } from "../../utils/env";
import chains from "../../utils/consts/chains";

// Defines two constants for theme modes
const mode = Object.freeze({
  light: "light",
  dark: "dark",
});

// Retrieves the initial theme mode from localStorage or uses the default light mode
export function getInitMode() {
  let result;
  try {
    result = localStorage.getItem(CACHE_KEY.themeMode) ?? mode.light;
  } catch (e) {
    // ignore parse error
    result = mode.light;
  }

  return result;
}

// Creates a slice of the Redux store for managing theme settings
const settingSlice = createSlice({
  name: "setting",
  initialState: {
    mode: getInitMode(), // Stores the current theme mode
    chain: getEnvChain(), // Stores the current blockchain chain
  },
  reducers: {
    setMode(state, { payload }) {
      localStorage.setItem(CACHE_KEY.themeMode, payload); // Updates the theme mode in localStorage
      state.mode = payload; // Updates the theme mode in the state
    },
  },
});

// Selectors for accessing specific parts of the setting state
export const modeSelector = (state) => state.setting.mode; // Selects the current theme mode
export const chainSelector = (state) => state.setting.chain; // Selects the current blockchain chain

// Selector that retrieves the chain setting based on the current chain
export const chainSettingSelector = createSelector(chainSelector, (chain) => {
  const setting = Object.values(chains).find((item) => item.value === chain);
  if (!setting) {
    throw new Error(`Can not find chain setting of ${chain}`);
  }
  // eslint-disable-next-line no-console
  // console.log("chains",chains)
  // eslint-disable-next-line no-console
  // console.log("chains selector", chainSelector)
  // eslint-disable-next-line no-console
  // console.log("setting",setting)

  return setting;
});

// Exported action creators
export const { setMode } = settingSlice.actions;

// Export the setting reducer
export default settingSlice.reducer;
