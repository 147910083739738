/* eslint-disable no-console */
// Import necessary modules and constants
import io from "socket.io-client";
import {
  latestBlocksKey,
  latestBlocksRoom,
  latestSignedTransfersKey,
  latestSignedTransfersRoom,
  overviewKey,
  overviewRoom,
} from "./consts";
import { getEnvEndpoint } from "../../utils/env";
import { store } from "../../store";
import {
  setLatestBlocks,
  setLatestSignedTransfers,
  setOverview,
  setLatestBlocksLoading,
  setLatestSignedTransfersLoading,
} from "../../store/reducers/socketSlice";
import { ApiPromise, WsProvider } from '@polkadot/api'


// Initialize socket variable
let socket = null;

// Function to connect to the socket server
export function connect() {
  console.log('SOCKET RUNNING....')
  // If socket connection exists, unsubscribe from previous rooms and disconnect
  if (socket) {
    socket.emit("unsubscribe", latestBlocksRoom);
    socket.emit("unsubscribe", latestSignedTransfersRoom);
    socket.emit("unsubscribe", overviewRoom);
    socket.disconnect();
  }

  // eslint-disable-next-line no-console
  // console.log("endpoint", getEnvEndpoint())
  // Connect to the socket server using the endpoint from the environment
    // socket = io(new URL(`/`, getEnvEndpoint()));

  socket = io(new URL(`/`, getEnvEndpoint()).href);
  console.log("socket connection:",getEnvEndpoint())
  

  socket.connect();

  // Set loading states for latest blocks and signed transfers
  store.dispatch(setLatestBlocksLoading(true));
  store.dispatch(setLatestSignedTransfersLoading(true));

  // Event handler for successful socket connection
  socket.on("connect", () => {
    console.log('SOCKET CONNECTING....')
    // Subscribe to rooms for latest blocks, signed transfers, and overview
    socket.emit("subscribe", latestBlocksRoom);
    socket.emit("subscribe", latestSignedTransfersRoom);
    socket.emit("subscribe", overviewRoom);

    // Event handlers for receiving data from socket server
    socket.on(latestBlocksKey, (data) => {
      console.log('SOCKET GETTING LATEST BLOCK....')
      console.log("LATEST BLOCK", data)
      // Dispatch action to store latest blocks data and set loading state to false
      store.dispatch(setLatestBlocks(data));
      store.dispatch(setLatestBlocksLoading(false));
    });
    socket.on(latestSignedTransfersKey, (transferData) => {
      console.log("latest signed socket")
      // Dispatch action to store latest signed transfers data and set loading state to false
      store.dispatch(setLatestSignedTransfers(transferData));
      store.dispatch(setLatestSignedTransfersLoading(false));
    });
    socket.on(overviewKey, (overviewData) => {
      // Dispatch action to store overview data
      store.dispatch(setOverview(overviewData));
    });
  });
}

// Function to unsubscribe from rooms for homepage information
export function unSubscribeHomepageInfo() {
  if (socket) {
    console.log('SOCKET UNSUBSCRIBING....')

    // Unsubscribe from latest blocks, signed transfers, and overview rooms
    socket.emit("unsubscribe", latestBlocksRoom);
    socket.emit("unsubscribe", latestSignedTransfersRoom);
    socket.emit("unsubscribe", overviewRoom);
  }
}
