// Import the `chains` object from the "./consts/chains" module
import chains from "./consts/chains";

// Function to get the public chain from the environment
export function getEnvChain() {
  // Check the value of the `REACT_APP_PUBLIC_CHAIN` environment variable
  const chain = process.env.REACT_APP_PUBLIC_CHAIN ;

  // Validate that the chain is a valid option from the `chains` object
  if (!Object.keys(chains).includes(chain)) {
    throw new Error(`Invalid REACT_APP_PUBLIC_CHAIN setting: ${chain}`);
  }

  // Return the selected chain
  return chain;
}



export function getEnvEndpoint() {
  const endpoint = process.env.REACT_APP_PUBLIC_API_END_POINT;
  if (!endpoint) {
    throw new Error(`No REACT_APP_PUBLIC_API_END_POINT is set`);
  }

  return endpoint;
}

// Function to get the public API endpoint from the environment
// export function getEnvEndpoint() {
//   // Check the value of the `REACT_APP_PUBLIC_API_END_POINT` environment variable
//   const endpoint =
//     process.env.REACT_APP_PUBLIC_API_END_POINT ||
//     "http://127.0.0.1:5010";

//   // Validate that an endpoint is set
//   if (!endpoint) {
//     throw new Error(`No REACT_APP_PUBLIC_API_END_POINT is set`);
//   }

//   // Return the selected endpoint
//   return endpoint;
// }
