/* eslint-disable no-console */
const paramsKeyConvert = (str = "") =>
  str.replace(/[A-Z]/g, ([s]) => `_${s.toLowerCase()}`);

class Api {
  endpoint = null;

  constructor(endpoint) {
    this.endpoint = endpoint;
  }

  fetch = (path, params = {}, options) => {
    const url = new URL(path, this.endpoint);
    console.log("url:",url)
    for (const key of Object.keys(params)) {
      url.searchParams.set(paramsKeyConvert(key), params[key]);
    }
    // console.log('URL:', url); // Log the response
    // console.log('API ENDPOINT:', this.endpoint); // Log the response
    // console.log('Path:', path); // Log the response



    return new Promise((resolve, reject) =>
    fetch(url, options)
      .then((resp) =>
        resp.status !== 200
          ? reject({ error: { status: resp.status } })
          : resp.json().then((result) => {
              console.log('Response API:', result); // Log the response
              resolve({ result });
            })
      )
      .catch((e) => {
        if (e.message === "The user aborted a request.") {
          reject(e);
        } else {
          console.log('Error:', e); // Log the error
          resolve({
            error: {
              status: 500,
              message: "can not parse response as JSON",
              data: null,
            },
          });
        }
      }),
  );

  };

  post = async (path, body = null) => {
    return await this.fetch(
      path,
      {},
      {
        method: "POST",
        credentials: "same-origin",
        body: body ? JSON.stringify(body) : null,
        headers: { "Content-Type": "application/json" },
      },
    );
  };
}

export default new Api(
  new URL(process.env.REACT_APP_PUBLIC_API_END_POINT || "https://polkadot-api.statescan.io").href,
);
