/* eslint-disable no-console */
// Import required modules and constants
import { ApiPromise, Keyring, WsProvider } from "@polkadot/api";
import { u8aToHex } from "@polkadot/util";
import { blake2AsU8a, decodeAddress, xxhashAsU8a } from "@polkadot/util-crypto";
import {
  DEFAULT_KUSAMA_NODES,
  DEFAULT_KUSAMA_NODE_URL,
  DEFAULT_POLKADOT_NODES,
  DEFAULT_POLKADOT_NODE_URL,
} from "../utils/constants";

// Determine the node URL based on stored or default values
let nodeUrl = (() => {
  let localNodeUrl = null;
  try {
    localNodeUrl = JSON.parse(localStorage.getItem("nodeUrl"));
  } catch (e) {
    // Ignore parse error and set localNodeUrl to null
  }
  return {
    kusama:
      DEFAULT_KUSAMA_NODES.find((item) => item.url === localNodeUrl?.kusama)
        ?.url || DEFAULT_KUSAMA_NODE_URL,
    polkadot:
      DEFAULT_POLKADOT_NODES.find((item) => item.url === localNodeUrl?.polkadot)
        ?.url || DEFAULT_POLKADOT_NODE_URL,
  };
})();

// Create a map to store API instances for different node URLs
const apiInstanceMap = new Map();

// Function to get the API instance for a specific chain and URL
export async function getChainApi(chain, queryUrl) {
  const url = queryUrl || nodeUrl?.[chain];
  // console.log("url query", queryUrl)
  // console.log("node url ", nodeUrl?.[chain])
  // console.log("url ", url)

  // Function to get blocks 
  //  const api = await ApiPromise.create({ provider:  new WsProvider(url) });
  //   await api.rpc.chain.subscribeNewHeads(async (header) => {
  //       console.log(`Chain is at block: #${header.number}`);
  //       const blockHash = await api.rpc.chain.getBlockHash(header.number);
  //       console.log(`blockHash: ${blockHash}`);
  //       const at = await api.at(blockHash);
  //       const events = await at.query.system.events();
  //       console.log("events",events.toHuman());
  //   });

  if (!apiInstanceMap.has(url)) {
    // Create a new API instance with the URL and store it in the map
    apiInstanceMap.set(
      url,
      ApiPromise.create({ provider: new WsProvider(url) , noInitWarn: true})
    );
  }

  // Return the API instance from the map
  return apiInstanceMap.get(url);
}

// Function to estimate the time required to produce a certain number of blocks
export const estimateBlocksTime = async (chain, blocks) => {
  const api = await getChainApi(chain);
  // console.log("api", api)
  const nsPerBlock = api.consts.babe.expectedBlockTime.toNumber();
  console.log("blocks from estimate block",blocks)
  // Calculate the estimated time in nanoseconds
  return nsPerBlock * blocks;
};

export const getLatestBlock = async (chain) =>{
  const api = await getChainApi(chain);
  const latestBlock = api.derive.chain.bestNumber
  return latestBlock
}
export const getFinalizeBlock = async (chain) =>{
  const api = await getChainApi(chain);
  const latestBlock = api.derive.chain.bestNumberFinalized
  return latestBlock
}

export const getTotalIssuanxe = async (chain) =>{
  const api = await getChainApi(chain);
  const issuance = await api.query.balances.totalIssuance()
  const value = issuance.toString();
  return value
}

// function getAccountStorageKey(address) {
//   const section = xxhashAsU8a("System", 128);
//   const method = xxhashAsU8a("Account", 128);
//   const id = decodeAddress(address);
//   const hash = blake2AsU8a(id, 128);

//   return u8aToHex(new Uint8Array([...section, ...method, ...hash, ...id]));
// }

export const getTotalAccount = async (chain) =>{
  const api = await getChainApi(chain);
  const keyring = new Keyring({type: 'sr25519',})
  const { meta, method, section } = api.query.system.account;
  console.log("meta:",meta)
  console.log("method:",method)
  console.log("section:",section)
  const accounts =  keyring.account;
  console.log("accounts:",accounts)

}


